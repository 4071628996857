import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false;

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
import './init';
import './permission';
import './css/index.less';

// title: '阅卷及一键归档系统'
// title: '电子卷宗智能阅卷系统'

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')


// http://192.168.0.79:8082/caseReading?caseName=（2021）冀01民初666号&token=OhP%2Fah%2Fa9A9ZLv8a33caVSXKZdXvfbvOuTZcnQQkoYzf7A9SUXIefVwGTot5%2BNaczqGTEnyO0042N9rF9%2FankP9z8YEaLRz0%2FkxIQpl%2BDfuDGek36uasdhTexcTjzxj0mBftrZfU3zS7O7kPGicf6mLOhLO4nUOTyFRdJk0FDc8%3D&role=HY-MAKER