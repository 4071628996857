const getters = {
    sideBarList: state => state.layout.sideBarList,
    allCaseType: state => state.common.allCaseType,
    topBarKeyword: state => state.common.topBarKeyword,
    record: state => state.common.record,
    permitList: state => state.common.permitList,
    userInfo: state => state.common.userInfo,
    settings: state => state.common.settings,
    imgScale: state => state.common.settings.imgScale || 30,
    configs: state => state.common.configs,

    archiveStatusList: state => state.common.archiveStatusList,
    dictMap: state => state.common.dictMap,
    token: state => state.user.token,
    source: state => state.user.source,
}

export default getters
