import Vue from 'vue'
import Vuex from 'vuex'
import persistedState from 'vuex-persistedstate'
Vue.use(Vuex)

import getters from './getters';

import layout from './modules/layout';
import common from './modules/common';
import user from './modules/user';

export default new Vuex.Store({
	modules: {
		layout,
		common,
		user
	},
	getters,
    plugins: [
        persistedState({
        //     //默认使用localStorage来固化数据，也可使用sessionStorage，配置一样
            storage: window.localStorage,
            reducer(val) {
                return {
                    // 只储存state中的值
                    user: {
						token: val.user.token,
                        source: val.user.source,
					}
                }
            }
        })
    ]
})
