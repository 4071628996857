import store from '@/store';

Date.dateFormat = function (data, fmt) {
    let date = new Date(data);
    let ret;
    const opt = {
        "Y+": date.getFullYear().toString(),        // 年
        "m+": (date.getMonth() + 1).toString(),     // 月
        "d+": date.getDate().toString(),            // 日
        "H+": date.getHours().toString(),           // 时
        "M+": date.getMinutes().toString(),         // 分
        "S+": date.getSeconds().toString()          // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
    };
    if (!fmt) {
        fmt = 'YY-mm-dd HH:MM:SS';
    }
    for (let k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) {
            fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
        };
    };
    return fmt;
}



if (!localStorage.getItem('permitList') || localStorage.getItem('permitList') === 'undefined') {
    localStorage.setItem('permitList', JSON.stringify([]));
} else {
    let permitList = JSON.parse(localStorage.getItem('permitList') || '[]');
    store.commit('common/setPermitList', permitList);
}

if (!localStorage.getItem('userInfo')) {
    localStorage.setItem('userInfo', JSON.stringify({}));
} else {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    store.commit('common/setUserInfo', userInfo);
}

if (document.documentMode) {        //  IE浏览器没有new File
    window.File = class File extends Blob {
        constructor(chunks, filename, opts = {}) {
            super(chunks, opts);
            this.lastModifiedDate = new Date();
            this.lastModified = + this.lastModifiedDate;
            this.name = filename;
        }
    }
}

