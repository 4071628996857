const state = {
    sideBarList: [
        {
            icon: 'iconshu',
            text: '电子卷宗',
            url: '/home',
            permissions: '/caseinfo/search-page'
        },
        // {
        //     icon: 'iconshezhi',
        //     text: '系统设置',
        //     url: '/system'
        // }
    ]
}

const mutations = {
    setSideBarList(state,data){
        state.sideBarList = data;
    }
}

const actions = {
    
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
