import { getSetting , setSetting , getConfigs , getDict } from '@/api/commonAPI.js';
import { deepAssign } from '@/utils/handle.js';

const state = {
    allCourt: [],
    allCaseType: [],
    record: {},
    permitList: [],
    userInfo: {},
    settings: {         //  所有用户个人配置信息，云存储

    },       
    configs: {           //  配置信息
        'GXFYY_FastReaderUrl': '',
        'renameFile': '',
        'reviewSettings': '',
        'uploadFileRules': '',
        'loginType': '',
        'userConfig': {
            login: {
                captcha: {
                    enabled: false,
                }
            },
            password: {
                rsa: {
                    public: ''
                }
            }
        }
    },
    dictMap: {
        case_template_mapping: '',
        case_template: '',
        case_type_std: '',
        file_source: [],
        file_source: [],
        file_media: [],
        gd_status: [],              //  归档状态字典
        case_type: [],          
    },
}

const mutations = {
    setAllCaseType(state, data) {
        state.allCaseType = data;
    },
    setRecord(state,data){
        state.record[data.key] = data.value;
    },
    setPermitList(state,data){
        state.permitList = data.filter(item => item != '');
        localStorage.setItem('permitList',JSON.stringify(data));
    },
    setUserInfo(state,data){
        state.userInfo = data;
        localStorage.setItem('userInfo',JSON.stringify(data));
    },
    setSetting(state,data){
        state.settings = data;
    },
    SET_CONFIGS(state,data){
        state.configs = data;
    }
    
}
let timer = null;

const actions = {
    getConfigInfo({ state , commit }){
        return new Promise((resolve,reject) => {
            const configs = JSON.parse(JSON.stringify(state.configs));
            const keys = Object.keys(configs).join(',');
            getConfigs({ keys }).then(res => {
                const source = {};
                Object.keys(configs).forEach((key,index) => {
                    source[key] = res[index];
                })
                deepAssign(configs,source);
                commit('SET_CONFIGS',configs);
                
                resolve();
            }).catch(err => {
                console.log(err);
                resolve();
            })
        })
    },
    getDictMap({ state }){
        getDict({ category: Object.keys(state.dictMap).join(',') }).then(res => {
            Object.keys(state.dictMap).forEach((key,index) => {
                state.dictMap[key] = res[index];
            })
        })
    },
    getSetting({ state }){
        getSetting('review').then(res => {
            state.settings = res;
        })
    },
    setSettingKey({ state , commit },data){
        // Vue.set(state.settings,data.key,data.value);
        const settings = JSON.parse(JSON.stringify(state.settings));
        settings[data.key] = data.value;
        commit('setSetting',settings);

        clearInterval(timer);
        timer = setInterval(() => {
            setSetting(state.settings);       //  保存个人配置(防抖)
            clearInterval(timer);
        },2000)
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
