import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)


const asyncRoutes = [
	{
		path: 'home',
		component: () => import('@/views/onlineDossier')
	},
	{
		path: 'caseSearch',
		component: () => import('@/views/caseSearch')
	},
	{
		path: 'record',
		component: () => import('@/views/record')
	},

]

const routes = [
	{
		path: '/demo',
		component: () => import('@/components/demo.vue')
	},
	{
		path: '/login',
		component: () => import('@/views/login')
	},
	{
		path: '*',
		component: () => import('@/components/404.vue')
	},
	{
		path: '/',
		redirect: '/home',
		component: () => import('@/layout'),
		children: asyncRoutes
	},
	{
		path: '/caseReading',
		component: () => import('@/views/caseReading')
	},
]

const router = new VueRouter({
	routes,
	mode: 'history'
})

export default router
