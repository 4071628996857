import { userLogin } from '@/api/loginApi.js';
import { JSEncrypt } from 'jsencrypt';

function encrtyFunc(value,publicKey){
	if(value.length > 128){
		return value;
	}
	// 创建JSEncrypt对象
	const encrypt = new JSEncrypt();

	// 设置公钥
	encrypt.setPublicKey(publicKey);

	// 加密密码
	var encryptedPassword = encrypt.encrypt(value);

	return encryptedPassword;
}

const state = {
    token: '',
    source: '',
}

const mutations = {
    SET_TOKEN(state,token) {
        state.token = token;
    },
    SET_SOURCE(state,source) {
        state.source = source;
    }
}

const actions = {
    login({ state , rootState , commit }, params) {
        const userConfig = rootState.common.configs['userConfig'];
		const publicKey = userConfig.password.rsa.public;
        
		if(publicKey){
			params.password = encrtyFunc(params.password,publicKey);
		}

		return userLogin(params).then(res => {
			const token = `${res.token_type} ${res.access_token}`;
			commit('SET_TOKEN', token);
            
            
		})
	},
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
