<template>
    <transition name="tooltip">
        <div class="tooltip" :style="style()" v-if="visible">
            {{text}}
            <span class="popper-arrow bottom"></span>
        </div>
    </transition>
</template>

<script>
export default {
    data(){
        return {
            text: '',
            left: 0,
            top: 0,
            visible: false
        }
    },
    methods: {
        style(){
            return {
                'left': `${this.left}px`,
                'top': `${this.top}px`
            }
        }
    }
}
</script>

<style lang="less" scoped>
.tooltip{
    background-color: #303133;
    color: #fff;
    padding: 10px;
    position: absolute;
    font-size: 12px;
    border-radius: 4px;
    left: 200px;
    top: 300px;
    margin-top: 10px;
    line-height: 1;
    .popper-arrow{
        position: absolute;
    }
    .popper-arrow.bottom{
        top: -5px;
        left: 10px;
    }
    .popper-arrow.bottom::after{
        border-top: none;
        border-bottom-color: #303133;
    }
    .popper-arrow::after{
        content: '';
        display: block;
        border: 5px solid transparent;
        // border-right: none;
        // border-left-color: #303133;
    }
    
}
.tooltip-enter-active,.tooltip-leave-active{
    transition: opacity .5s;
}

.tooltip-enter,.tooltip-leave{
    opacity: 0;
}

</style>