import service from '@/utils/request';

export function getAllCaseInfo(params){
    return service.get('/caseinfo/search-page',{
        params,
        baseURL: '/api_v2',
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    })
}

export function getOrgList(params){
    return service.get('/user/org/list',{
        params,
        baseURL: '/api_v2',
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    })
}

export function getDict(params){
    return service.get('/caseinfo/dict/tree',{
        params,
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    })
}

export function getConfigs(params){     //  获取登录配置信息---是否有快速阅卷跳转按钮----广西
    return service.get('/basic/config/get',{
        params,
        
    })
}

export function saveExtra(data,params){        //  保存扩展字段（案件封皮信息）
    return service.post(`/caseinfo/save-extra`,data,{
        params
    })
}

export function getSetting(params){    //  获取配置
    return service.get(`/user/base/settings/${params}`)
}

export function setSetting(data){    //  保存配置
    return service.post('/user/base/settings/review',data,{
        
    })
}

export function hasPermit(params){    //  验证用户是否能操作该卷宗
    return service.get('/caseinfo/has-permission',{
        params,
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    })
}

export function queryFileOwner(params){    //  查询材料归属信息（外部）
    return service.get('/caseinfo/case-type/case-template/query-file-owner',{
        params,
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    })
}

