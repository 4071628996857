import Vue from 'vue';
import router from './router'
import { getUserInfo } from '@/api/loginApi';
import store from '@/store';

let hasConfig = false;

router.beforeEach(async (to, from, next) => {
    if(to.query && to.query.WHRP_TOKEN){
        store.commit('user/SET_TOKEN',to.query.WHRP_TOKEN);
        store.commit('user/SET_SOURCE',to.query.source);
        // next();
    }

    if(!hasConfig){
        await store.dispatch('common/getConfigInfo');
    }
    // return;
    if (to.path === "/login") {
        document.title = '阅卷及一键归档系统';
        store.commit('user/SET_TOKEN','');
        next();
    } else {
        getUserInfo().then(res => {
            store.commit('common/setPermitList', res.permissions);
            store.commit('common/setUserInfo', res);
            next();
        })
        store.dispatch('common/getSetting');
        store.dispatch('common/getDictMap');
    }



})

// 注册一个全局自定义指令 `v-hasPermit`
Vue.directive('hasPermit', {
    // 当被绑定的元素插入到 DOM 中时……
    inserted: function (el, binding, vnode, oldVnode) {
        const permitList = store.state.common.permitList
        // debugger
        // loading    
        // 聚焦元素
        // console.log(el);    //  元素
        // console.log(binding);   //  对象
        // console.log(vnode);     //  虚拟节点
        // console.log(oldVnode);  //  上一个虚拟节点
        
        let url = el.getAttribute('url');
        
        // console.log(url,el);
        if(url === '**' || url === '' || !url){
            return;
        }
        if (url) {
            let urlList = url.split(',');
            
            let flag = permitList.find(permit => {
                if (permit.indexOf("**") != -1) {
                    permit = permit.replace("**", ".*");
                } else {
                    if (permit.indexOf("*") != -1) {
                        permit = permit.replace("*", "[a-zA-Z0-9_\\-]*");
                    }
                }
                return urlList.find(url => new RegExp(`^${permit}$`).test(url));
            })
            
            if (!flag) {      //  未拥有权限（url）
                el.parentNode.removeChild(el);
            }
        } else {
            el.parentNode.removeChild(el);
        }
    },

})

import Tooltip from '@/common/utils/tooltip';
const TooltipContainer = Vue.extend(Tooltip);
// console.log(Tooltip);
let instance;
        
            
instance = new TooltipContainer({        //  实例才能挂载($mount)
    
}); 
instance.$mount();
document.body.appendChild(instance.$el);

// 注册一个全局自定义指令 `v-hasPermit`
Vue.directive('tooltip', {
    // 当被绑定的元素插入到 DOM 中时……
    bind: function (el, binding, vnode, oldVnode) {
        // 聚焦元素
        
        el.addEventListener('mouseenter',event => {
            const content = el.getAttribute('data-tip');
            let position = el.getBoundingClientRect();
            instance.left = position.left;
            instance.top = position.bottom;
            instance.visible = true;
            instance.text = content
        })

        el.addEventListener('mouseleave',event => {
            instance.visible = false;
        })
    },
})

// Vue.directive('loading',{
//     update: function (el, binding, vnode, oldVnode) {
//         // 聚焦元素
//         // console.log(el);    //  元素
//         // console.log(binding);   //  对象
//         // console.log(vnode);     //  虚拟节点
//         // console.log(oldVnode);  //  上一个虚拟节点
//         // console.log(render);

//         // console.log(Tooltip);
//         // console.log(Vue.createElement);
//         // vnode.children.push(Tooltip);
//         const content = binding.value;
//         // console.log(binding);
        

//     }
// })