import service from '@/utils/request';

export function userLogin(data){
    return service.post('/user/login',data,{
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        baseURL: '/api_v2'
    })
}

// export function getResourse(params){
//     return service.get('/user/own-resources',{
//         params,
//         headers: {'Content-Type': 'application/x-www-form-urlencoded'},
//         baseURL: '/api_v2'
//     })
// }

export function getUserInfo(params){
    return service.get('/user/base/base-info',{
        params,
        // headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        baseURL: '/api_v2'
    })
}

export function userLogout(params){
    return service.get('/user/logout',{
        params,
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        baseURL: '/api_v2'
    })
}

//	验证码
export const getCode = () => {
	return service.get(`/user/captcha?a=${Math.random()}`,{
		headers: { "Content-Type": "application/x-www-form-urlencoded" },
		responseType: 'blob'
	});
}