import axios from 'axios'
import store from '@/store/index.js';
import { Message } from 'element-ui';
import { userConfig } from '@/api/loginApi';
import router from '@/router';

const service = axios.create({
    baseURL: '/api_v2',
    timeout: 600000,
})

function getQueryVariable(variable){
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i=0;i<vars.length;i++) {
            var pair = vars[i].split("=");
            if(pair[0] == variable){return pair[1];}
    }
    return(false);
}

async function errMessage(errData) {
    if (errData.code === 401) {
        let currentHref = window.location.href;
        if(document.documentMode){
            //  IE浏览器(才编码)
            let result = window.location.search.split('&').map(item => {
                const key = item.split('=')[0];
                const value = encodeURIComponent(item.split('=')[1]);
                return `${key}=${value}`;
            }).join('&')
            let origin = window.location.origin;
            if (!origin) {
                origin = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
            }
            console.log(origin);
            currentHref = origin + window.location.pathname + result;
        }
        let source = getQueryVariable('source');        //  客户端来源
        if(source){
            store.commit('user/SET_SOURCE',source);
        }

        let account = getQueryVariable('account');
        let timestamp = getQueryVariable('timestamp');
        let signature = getQueryVariable('signature');
        if(account && timestamp && signature){
            let redirectUrl = currentHref.replace(/[\?&](account|timestamp|signature)[^&]*/g, "").replace(/[\?&]/, '?');
            let params = currentHref.match(/[\?](.*)/)[1];
            
            let path = `/api_v2/user/login?${params}&redirectUrl=${encodeURIComponent(redirectUrl)}`;
            window.location.href = path;

            return;
        }
        
        const loginType = store.getters.configs['loginType'];
        // console.log(loginType);
        if (loginType === 'CAS') {       //..   无登陆界面
            window.location.href = `/api_v2/user/login?redirectUrl=${encodeURIComponent(currentHref)}`;
        } else if (loginType === 'SELF') {       //..   有/无登陆界面---保定
            // router.push('/login');
            let redirectUrl = currentHref.replace(/[\?&]token=[^&]*/, "").replace(/[\?&]/, '?');
            let token = getQueryVariable('token');
            let role = getQueryVariable('role');
            // console.log(token);
            // return;
            if(token){
                localStorage.setItem('token',token);
                console.log(encodeURIComponent(redirectUrl));
                console.log(`/api_v2/user/login?redirectUrl=${encodeURIComponent(redirectUrl)}&token=${token}&role=${role}`);
                // return;
                if(role){
                    // window.location.href = `/api_v2/user/login?redirectUrl=${redirectUrl}&token=${token}&role=${role}`;
                    window.location.href = `/api_v2/user/login?redirectUrl=${encodeURIComponent(redirectUrl)}&token=${token}&role=${role}`;
                }else{
                    window.location.href = `/api_v2/user/login?redirectUrl=${encodeURIComponent(redirectUrl)}&token=${token}`;
                }
                return;
                
            }else{
                // console.log(router);
                router.push({
                    path: '/login',
                    query: {
                        redirectUrl: location.href
                    }
                });
            }
            return;
        } else if (loginType === 'SSO') {       //..   无登陆界面---广西
            let redirectUrl = currentHref.replace(/[\?&]token=[^&]*/, "").replace(/[\?&]/, '?');
            let token = getQueryVariable('token');
            if(token){
                localStorage.setItem('token',token);
            }
            window.location.href = `/api_v2/user/login?redirectUrl=${encodeURIComponent(redirectUrl)}&token=${token}`;
            return;
        }
    }

    if(errData.type === "application/json"){            //  当responseType为blob的时候，返回是一个json文件
        const reader  = new FileReader(); 
        reader.readAsText(errData, 'utf-8'); 
        reader.onload= function(){
            const { message }  = JSON.parse(reader.result);
            Message.error(message);
        }
    }
    if(errData.message)
    Message.error(errData.message);
}

function setLoading(url, flag) {      //      按钮loading效果
    if (url === '/caseinfo/directory/query-mapping') {
        return;
    }
    let doms = document.querySelectorAll(`[url="${url}"]`);
    if (doms.length) {
        doms.forEach(dom => {
            if (flag) {
                dom.classList.add('rotate');
                dom.setAttribute('disabled', 'disabled');
            } else {
                dom.classList.remove('rotate');
                dom.removeAttribute('disabled');
            }
        })

    }
}

service.interceptors.request.use(config => {
    const token = store.getters.token;
    if(token){
        config.headers['Authorization'] = token;
    }

    let data = store.getters.record[config.url];        //  添加操作记录参数
    if (data) {
        config.params ? '' : config.params = {};
        Object.keys(data).forEach(key => {
            data;
            config.params[key] = data[key];
        })
    }
    setLoading(config.url, true);
    if (config.headers['Content-Type'] === 'application/x-www-form-urlencoded') {
        let form = new URLSearchParams();
        for (const key in config.data) {
            form.append(key, config.data[key]);
        }
        config.data = form;
        return config;
    }
    if (config.headers['Content-Type'] === 'multipart/form-data') {
        let form = new FormData();
        for (const key in config.data) {
            form.append(key, config.data[key]);
        }
        config.data = form;
    }
    // return;

    return config
}, (error) => Promise.reject(error))

let whiteList = ['/basic/config/get','/caseinfo/file/upload'];

service.interceptors.response.use(response => {
    // console.log(response);
    setLoading(response.config.url, false);

    if (response.request.responseType === 'blob') {
        return response;
    }
    return response.data;
}, error => {
    // console.log('print' + error);
    // console.log(error.response);
    let errorURL = error.response.config.url;
    setLoading(error.response.config.url, false);
    errMessage(error.response.data);
    if(whiteList.includes(errorURL)){
        console.log(error.response);
        return Promise.reject(error.response.data);
    }

    return Promise.reject(error)
})

export default service
