export function deepAssign(target,source){          //  深度合并对象
    // console.log(target,source);
    // debugger
    // console.log(JSON.parse(JSON.stringify(target)),source);
    if(source.constructor === Object){
        
        if(target.constructor === Object){
            Object.keys(source).forEach(sourceKey => {
                // debugger
                const targetItem = target[sourceKey];
                const sourceItem = source[sourceKey];
                const targetType = targetItem && targetItem.constructor;
                const sourceType = sourceItem && sourceItem.constructor;
    
                if(targetType === Object && sourceType === Object){
                    deepAssign(targetItem,sourceItem);                  //  都为对象的时候才会进行递归合并
                }else if(targetType === Array && sourceType === Array){
                    deepAssign(targetItem,sourceItem);
                }else {
                    if(source[sourceKey]){
                        target[sourceKey] = source[sourceKey];
                    }
                }
            })
        }else{
            target = source;
        }
        return target;
    }else if(source.constructor === Array){
        if(target.constructor === Array){
            for (let i = 0; i < source.length; i++) {
                let targetItem = target[i];
                const sourceItem = source[i];
                const targetItemType = targetItem && targetItem.constructor;
                const sourceItemType = sourceItem && sourceItem.constructor;
                
                if(targetItemType === Object && sourceItemType === Object){
                    deepAssign(targetItem,sourceItem);
                }else if(targetItemType === Array && sourceItemType === Array){
                    deepAssign(targetItem,sourceItem);
                }else{
                    target.splice(i,0,sourceItem);
                    // target[i] = sourceItem;
                }
            }
        }else{
            target = source;
        }
    }
}